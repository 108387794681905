import {
    Box, Chip, CircularProgress, Dialog,
    DialogContent, DialogTitle, Grid, Icon, IconButton,
    LinearProgress, Tooltip,
    Typography
} from '@mui/material'
import PropTypes from 'prop-types';
import React from 'react'
import { FormattedMessage } from 'react-intl'


const SearchResultsDialog = ({ word, loading, onClickResult, showModal, setShowModal, resultsData }) => {


    return (
        <Dialog
            open={showModal}
            onClose={setShowModal}
            maxWidth="sm"
            fullWidth
            scroll='paper'
        >{loading ?
            <Box sx={{ py: 5, display: "flex", justifyContent: "center", height: "400", alignItems: "center" }} >
                <CircularProgress />
            </Box>
            :
            (word &&
                <>
                    <DialogTitle>
                        <Box display="flex">
                            <Box flexGrow={1} display="flex" alignItems="center">
                                <Typography variant="h5">
                                    <FormattedMessage
                                        id="translations_of"
                                        defaultMessage="Translations of {word}"
                                        values={{
                                            word: word
                                        }}
                                    /></Typography>
                            </Box>
                            <IconButton onClick={() => setShowModal(false)}><Icon>close</Icon></IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Grid spacing={2} container sx={{
                            display: "flex", alignItems: "center", justifyContent: "center"
                        }}>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    <FormattedMessage
                                        id="results_description"
                                        defaultMessage="We found more than one translation for the word {word}, please select one of the following translations to continue."
                                        values={{
                                            word: word
                                        }}
                                    />
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {resultsData.length > 0 ?
                                    <Grid container spacing={1} alignItems="center">

                                        <Grid item xs={8}>
                                            <Box display={"flex"} alignItems="center">
                                                <Typography variant="body1">
                                                    <FormattedMessage
                                                        id="words"
                                                        defaultMessage="Words"
                                                    />
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box display={"flex"} alignItems="center" justifyContent="flex-end">
                                                <Typography variant="body2" fontStyle="italic">
                                                    <FormattedMessage
                                                        id="frequency"
                                                        defaultMessage="Frequency"
                                                    />
                                                </Typography>
                                                <Tooltip title={
                                                    <FormattedMessage
                                                        id="frequency_tooltip"
                                                        defaultMessage="Indicates how often people relate this word with {word}"
                                                        values={{
                                                            word: word
                                                        }}
                                                    />
                                                } arrow>
                                                    <IconButton size='small'>
                                                        <Icon fontSize='small'>help</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>
                                        {resultsData.map((item, key) => (
                                            <Grid key={key} xs={12} item container spacing={2} justifyContent="center" alignItems={"center"}>
                                                <Grid item xs={4} md={2} >
                                                    <Chip label={item.transResult} onClick={onClickResult(item.transResult)} />
                                                </Grid>
                                                <Grid item xs={6} md={8} >
                                                    {item.reflectTrans.map((innerValue, innerKey) => <Typography key={innerKey} variant="caption">{innerValue}, </Typography>)}
                                                </Grid>
                                                <Grid item xs={2} md={2}>
                                                    <LinearProgress variant="determinate" value={(item.frequency / 3) * 100} />
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    :
                                    <Box>
                                        <FormattedMessage
                                            id="press_enter_to_search"
                                            defaultMessage="Press enter to search"
                                        />
                                    </Box>
                                }
                            </Grid>
                        </Grid>
                    </DialogContent>
                </>
            )
            }
        </Dialog >
    )
}

SearchResultsDialog.propTypes = {
    word: PropTypes.string,
    loading: PropTypes.bool,
    onClickResult: PropTypes.func,
    showModal:PropTypes.bool,
    setShowModal:PropTypes.func,
    resultsData:PropTypes.array,
}

export default SearchResultsDialog