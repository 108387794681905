import {
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";
import React, { useEffect, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';

import Home from "./views/Home";
import Tool from './views/Tool';

import SideBar from './components/SideBar';

import './App.css';
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import Team from "./views/Team";
import Contact from "./views/Contact";
import styled from "@emotion/styled";

import { IntlProvider } from 'react-intl';

import English from './lang/en.json';
import Japanese from './lang/jp.json';
import { useSelector } from "react-redux";

let theme = createTheme({
  palette: {
    primary: {
      main: "#FA6D51",
    },
    secondary: {
      main: "#323233",
    },
  },
});

theme = responsiveFontSizes(theme);

const drawerWidth = 240;

const Root = styled('div')(() => ({
  height: '100%',
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, ismobile }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    ...(open && !ismobile && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerWidth}px`,
    }),
  }),
);

function App() {

  const [isOpen, setIsOpen] = useState(false)
  const isMobile = useMediaQuery('(max-width:600px)');

  const messages = {
    'jp': Japanese,
    'en': English
  };

  useEffect(() => {
    if (!isMobile) {
      setIsOpen(true)
    }
    // eslint-disable-next-line
  }, [])

  const userReducer = useSelector(state => state.userReducer);

  let locale = navigator.language.split(/[-_]/)[0];

  if (userReducer.language) { locale = userReducer.language }

  return (
    <>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <ThemeProvider theme={theme}>
          <Root>
            <HashRouter>
              <SideBar
                drawerWidth={drawerWidth}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                isMobile={isMobile}
              />
              <Main open={isOpen} ismobile={isMobile ? isMobile : undefined}>
                <Routes>
                  <Route path="/" element={<Home isOpen={isOpen} setIsOpen={setIsOpen} />} />
                  <Route path="/team" element={<Team isOpen={isOpen} setIsOpen={setIsOpen} />} />
                  <Route path="/contact" element={<Contact isOpen={isOpen} setIsOpen={setIsOpen} />} />
                  <Route path="/tool" element={<Tool isOpen={isOpen} setIsOpen={setIsOpen} />} />
                </Routes>
              </Main>
            </HashRouter>
          </Root>
        </ThemeProvider>
      </IntlProvider>
    </>
  );
}

export default App;
