
const setLanguage = (newLang) => (dispatch) => {
    /* console.log("newLang");
    console.log(newLang); */

    dispatch({
        type: 'SET_LANGUAGE',
        payload: newLang
    });
}



const userActions = {
    setLanguage
}

export default userActions;