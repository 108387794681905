const INITIAL_STATE = {
  language: localStorage.language != null ? localStorage.language : 'en',
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    
    case "SET_LANGUAGE":
      localStorage.setItem("language", action.payload);
      return {
        ...state,
        language: action.payload
      };
    default:
      return state;
  }
};

export default userReducer;