import { Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import PropTypes from 'prop-types';
import React from 'react'
import { FormattedMessage } from 'react-intl'
import TopBar from '../components/TopBar'

const Team = ({ isOpen, setIsOpen }) => {

  return (
    <>
      <TopBar isOpen={isOpen} setIsOpen={setIsOpen} />
      <Container maxWidth="md" sx={{
        pt: 2
      }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h2">
              <FormattedMessage
                id="menu_team"
                defaultMessage="Team"
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>
              Maria Telegina
            </Typography>
            <Typography textAlign={"justify"}>
              <FormattedMessage
                id="maria_description"
                values={{
                  br: <br />,
                }}
                defaultMessage="Maria Telegina is a project assistant professor at Tokyo College, University of Tokyo, and a researcher in charge of the SWOW-JP APP project.
                She holds a Ph.D. from the University of Oxford, with her main research interests in Japanese linguistics, cognitive linguistics, and digital humanities.{br}
                For part of the doctoral project, she analyzed language association networks in Japanese as the first language and created a structural model of spatio-temporal concepts.
                During her time in Oxford, through her experience as a lecturer in Japanese, she gained an interest in language association networks and mental lexicons as applied to teaching Japanese as a second language.
                "
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>
              Simon De Deyne
            </Typography>
            <Typography textAlign={"justify"}>
              <FormattedMessage
                id="simon_description"
                values={{
                  br: <br />,
                }}
                defaultMessage="Simon De Deyne is a Postdoctoral Research Fellow at the University of Melbourne Human Complex Data Hub. His main research interest is the mental representation of words and concepts. He combines computational approaches from cognitive network science, AI, with big data from experiments and naturally occurring data (e.g. language, images) to learn how the mind acquires and represents word meaning. Current work addresses how meaning is derived from language and our experiences, how connotative meaning varies across different languages and cultures, and how bilinguals learn and represent word meaning.{br}
                One of his longest-running projects is the Small World of Words project. This community-driven project uses human word association data to map meaning in the mental lexicon across widely-spoken languages."
              />
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

Team.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
}


export default Team