
import { Box, ButtonBase, Grid, Icon, LinearProgress, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { bfsFilterNode } from '../utils/BFS.js';
import { communityColors, languageColors } from '../utils/colors.js';



const ChipWithProgress = ({ item = { label: "", links: [] }, onClick, progressValue, settings }) => {
    return (
        <ButtonBase
            focusRipple
            onClick={onClick}
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: settings.showGroupColors ?
                    communityColors[item.group] : (theme) => theme.palette.action.selected,
                borderRadius: "10px",
                p: 1,
                margin: 0.5
            }}
        >
            <Box >

                <Typography variant="body2">{item.label}</Typography>
                {settings.showMiniTranslations &&
                    <Typography variant="caption">{item.mainTranslation}</Typography>
                }
            </Box>
            {progressValue &&
                <Box width="100%">
                    <LinearProgress color='secondary' variant="determinate" value={progressValue} />
                </Box>
            }
        </ButtonBase>
    )
}

ChipWithProgress.propTypes = {
    item: PropTypes.any,
    onClick: PropTypes.func,
    progressValue: PropTypes.number,
    settings: PropTypes.object,
}


const ListGraph = ({ graphData, onClickNode, word, translatedWord, settings, communityColors }) => {


    const [localGraphData, setLocalGraphData] = useState({
        nodes: [], links: [], crossLinks: [],
        minFreqEN: 0, maxFreqEN: 0, minFreqJP: 0, maxFreqJP: 0
    });

    const [fromLanguage, setFromLanguage] = useState("")

    const hasJPNodes = localGraphData.nodes.find(item => item.fromLanguage === "jp");
    const hasENNodes = localGraphData.nodes.find(item => item.fromLanguage === "en");
    const hasRepeatedNodes = localGraphData.nodes.find(item => item.repeated);

    const normaliseJP = (value) =>
        ((value - localGraphData.minFreqJP) * 100) / (localGraphData.maxFreqJP - localGraphData.minFreqJP);
    const normaliseEN = (value) =>
        ((value - localGraphData.minFreqEN) * 100) / (localGraphData.maxFreqEN - localGraphData.minFreqEN);

    useEffect(() => {
        if (graphData !== undefined) {
            console.log("graphData")
            console.log(graphData)
            const newData = bfsFilterNode(settings.weight, graphData);
            console.log("newData")
            console.log(newData)
            let maxValueEN = 0;
            let minValueEN = Number.EPSILON;
            let maxValueJP = 0;
            let minValueJP = Number.EPSILON;
            for (let index = 0; index < newData.nodes.length; index++) {
                const element = newData.nodes[index];
                if (element.fromLanguage === "en") {
                    if (maxValueEN < element.frequency) {
                        maxValueEN = element.frequency;
                    }
                    if (minValueEN > element.frequency) {
                        maxValueEN = element.frequency;
                    }
                }
                if (element.fromLanguage === "jp") {
                    if (maxValueJP < element.frequency) {
                        maxValueJP = element.frequency;
                    }
                    if (minValueJP > element.frequency) {
                        maxValueJP = element.frequency;
                    }
                }

            }
            if (newData.nodes.length) {
                setFromLanguage(newData.nodes[0].fromLanguage);
            }
            setLocalGraphData({
                ...newData, maxFreqJP: maxValueJP, minFreqJP: minValueJP,
                maxFreqEN: maxValueEN, minFreqEN: minValueEN
            });
        }

    }, [graphData, settings.weight])

    return (
        <Box mt={10} p={2} mb={8}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent={"center"} alignItems={"center"}>
                        <Typography variant="h5">{word}</Typography>
                        <Icon>arrow_forward</Icon>
                        <Typography variant="h5">{translatedWord}</Typography>
                    </Box>
                </Grid>
                {hasRepeatedNodes &&
                    <Grid item xs={12}>
                        <Box pb={1} display="flex" alignItems={"center"}>
                            <Icon sx={{ pr: 1, color: "green" }}>trip_origin</Icon>
                            <Typography variant={"h6"}>
                                <FormattedMessage
                                    id="shared_associations"
                                    defaultMessage="Shares associations"
                                />
                            </Typography>
                        </Box>
                    </Grid>
                }
                {localGraphData.nodes && localGraphData.nodes.map((item, index) => (
                    index > 0 && fromLanguage == item.fromLanguage && item.repeated &&
                    <Grid key={index} item xs={6} sm={6} md={3} lg={2}>
                        <ChipWithProgress
                            communityColors={communityColors}
                            settings={settings}
                            item={item}
                            onClick={() => onClickNode(item)}
                            progressValue={settings.showFrequenciesValues ?
                                (item.fromLanguage == "en" ? normaliseEN(item.frequency) : normaliseJP(item.frequency))
                                :
                                null}
                        />
                    </Grid>
                ))
                }
                {hasENNodes &&
                    <Grid item xs={12}>
                        <Box pb={1} display="flex" alignItems={"center"}>
                            <Icon sx={{ pr: 1, color: languageColors["en"] }}>trip_origin</Icon>
                            <Typography variant={"h6"}>
                                <FormattedMessage
                                    id="english_specific_meanings"
                                    defaultMessage="English specific meanings"
                                />
                            </Typography>
                        </Box>
                    </Grid>
                }
                {localGraphData.nodes && localGraphData.nodes.map(item => (
                    item.fromLanguage === "en" && !item.repeated &&
                    <Grid item xs={6} sm={6} md={3} lg={2}>
                        <ChipWithProgress
                            communityColors={communityColors}
                            settings={settings}
                            item={item}
                            onClick={() => onClickNode(item)}
                            progressValue={settings.showFrequenciesValues ? normaliseEN(item.frequency) : null}
                        />
                    </Grid>
                ))
                }
                {hasJPNodes &&
                    <Grid item xs={12}>
                        <Box pb={1} display="flex" alignItems={"center"}>
                            <Icon sx={{ pr: 1, color: languageColors["jp"] }}>trip_origin</Icon>
                            <Typography variant={"h6"}>
                                <FormattedMessage
                                    id="japanese_specific_meanings"
                                    defaultMessage="Japanese specific meanings"
                                />
                            </Typography>
                        </Box>
                    </Grid>
                }
                {localGraphData.nodes && localGraphData.nodes.map(item => (
                    item.fromLanguage === "jp" && !item.repeated &&
                    <Grid item xs={6} sm={6} md={3} lg={2}>
                        <ChipWithProgress
                            communityColors={communityColors}
                            settings={settings}
                            item={item}
                            onClick={() => onClickNode(item)}
                            progressValue={settings.showFrequenciesValues ? normaliseJP(item.frequency) : null}
                        />

                    </Grid>
                ))
                }
            </Grid>


        </Box >
    )
}

ListGraph.propTypes = {
    graphData: PropTypes.array,
    onClickNode: PropTypes.func,
    word: PropTypes.string,
    translatedWord: PropTypes.string,
    settings: PropTypes.object,
    communityColors: PropTypes.array,
}

export default ListGraph