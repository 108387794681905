import { AppBar, Icon, IconButton, Toolbar } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types';

const TopBar = ({ isOpen, setIsOpen }) => {
    return (

        <AppBar position="static" elevation={0} sx={{
            backgroundColor: "transparent",
            color: "black"
        }}>
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    sx={{ mr: 2 }}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <Icon>menu</Icon>
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}

TopBar.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

export default TopBar