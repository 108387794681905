
export function bfsFilterNode(limitValue, graphData) {
    let leftRootNodeID;
    let rightRootNodeID;
    if (graphData.crossLinks) {
        leftRootNodeID = graphData['crossLinks'][0]['source']
        rightRootNodeID = graphData['crossLinks'][0]['target']
    }
    else {
        leftRootNodeID = graphData['nodes'][0]['id']
    }

    let nodeQueue = []
    let nodeKeepList = []
    let linkKeepList = []


    const nodes = [...graphData.nodes] || [];

    const links = [...graphData.links] || [];
    // transfer nodes to dict/ links
    let nodesDict = {};
    for (let index = 0; index < nodes.length; index++) {
        const nodeUnit = nodes[index];
        nodesDict[nodeUnit['id']] = nodeUnit;
    }

    let linksDict = {};
    for (let index = 0; index < links.length; index++) {
        const linkUnit = links[index];
        if (linkUnit['type'] == 'real') {
            linksDict[linkUnit['id']] = linkUnit;
        }
    }

    function BFS(rootNodeID) {
        nodeKeepList.push(rootNodeID);
        nodeQueue.push(rootNodeID);
        while (nodeQueue.length > 0) {
            let nodeID = nodeQueue.pop(0);
            let linkSave = [];
            for (let index = 0; index < nodesDict[nodeID]['links'].length; index++) {
                const link = nodesDict[nodeID]['links'][index];

                // no id case
                if (link['weight'] >= limitValue) {
                    linkKeepList.push(link['id']);

                    const exists = nodeKeepList.find(id => id === link['target']);
                    if (!exists) {
                        nodeKeepList.push(link['target']);
                        nodeQueue.push(link['target']);
                    }
                    linkSave.push(link);
                }
            }
        }
    }

    BFS(leftRootNodeID)
    if (graphData.crossLinks) {
        BFS(rightRootNodeID)
    }

    let virtualLinkList = []
    for (let index = 0; index < graphData.links.length; index++) {
        const linkUnit = graphData.links[index];
        if (linkUnit['type'] === 'simulated') {
            let sourceExists = false;
            let targetExists = false;
            for (let index = 0; index < nodeKeepList.length; index++) {
                let source = linkUnit['source'].id || linkUnit['source'];
                if (source == nodeKeepList[index]) {
                    sourceExists = true;
                }
                let target = linkUnit['target'].id || linkUnit['target'];
                if (target == nodeKeepList[index]) {
                    targetExists = true;
                }
            }
            if (sourceExists && targetExists) {
                virtualLinkList.push(linkUnit)
            }
        }
    }
    const newNodes = []
    const newLinks = []

    for (let index = 0; index < nodeKeepList.length; index++) {
        const nodeID = nodeKeepList[index];
        newNodes.push(nodesDict[nodeID])
    }
    for (let index = 0; index < linkKeepList.length; index++) {
        const linkID = linkKeepList[index];
        newLinks.push(linksDict[linkID])
    }

    return {
        nodes: newNodes,
        links: [...newLinks, ...virtualLinkList]
    }

}