import React from 'react'

import {
    Box, Chip, Grid, Icon, IconButton, LinearProgress,
    Skeleton, Tooltip, Typography
} from '@mui/material'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types';

const DetailsBlockContent = ({ loading, item, actionOpenNetwork }) => {

    const renderOtherTranslations = (title, translationsList) => {
        return (
            <Grid container spacing={1} alignItems="center">
                <>
                    <Grid item xs={8}>
                        <Box display={"flex"} alignItems="center">
                            <Typography variant="body1">
                                <FormattedMessage
                                    id={title.toLowerCase()}
                                    defaultMessage={title}
                                />
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box display={"flex"} alignItems="center" justifyContent="flex-end">
                            <Typography variant="body2" fontStyle="italic">
                                <FormattedMessage
                                    id="frequency"
                                    defaultMessage="Frequency"
                                />
                            </Typography>
                            <Tooltip title={`Indicates how often people relate this word with ${item.label}`} arrow>
                                <IconButton size='small'>
                                    <Icon fontSize='small'>help</Icon></IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </>
                {loading ?
                    <Skeleton animation="wave" variant="rectangular" height={40} width="100%" />
                    :
                    <>
                        {translationsList.map((item, key) => (
                            <React.Fragment key={key}>
                                <Grid item xs={4}>
                                    {item.exist ?
                                        <Chip
                                            label={item.transResult} onClick={() => actionOpenNetwork(item.transResult)}
                                            variant={"filled"} />
                                        :
                                        <Chip
                                            label={item.transResult}
                                            variant={"outlined"} />
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    <Box display="">
                                        {item.reflectTrans.map((innerValue,innerKey) =>
                                            <Typography key={innerKey} variant="caption">{innerValue}, </Typography>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <LinearProgress variant="determinate" value={(item.frequency / 3) * 100} />
                                </Grid>
                            </React.Fragment>
                        ))}
                    </>
                }

            </Grid>
        )
    }

    return (
        <Grid container spacing={1}>
            {(item.mainTranslation && (Object.keys(item.translations).length !== 0)) ?
                <>
                    <Grid item xs={12}>
                        {loading ?
                            <Skeleton animation="wave" variant="rectangular" height={20} width="100%" />
                            :
                            <>
                                <Typography variant="body1" fontWeight={300}>
                                    <FormattedMessage
                                        id="translations_of"
                                        defaultMessage="Translations of {word}"
                                        values={{
                                            word: item.label
                                        }}
                                    />
                                </Typography>
                                {item.translations['adjective'] &&
                                    renderOtherTranslations("Adjectives", item.translations['adjective'])
                                }
                            </>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {loading ?
                            <Skeleton animation="wave" variant="rectangular" height={20} width="100%" />
                            :
                            <>
                                {item.translations['noun'] &&
                                    renderOtherTranslations("Nouns", item.translations['noun'])
                                }
                            </>
                        }
                    </Grid>
                </>
                : <Grid item xs={12}>
                    <Box display="flex">
                        {!loading &&
                            <Typography variant="body1" fontStyle="italic">
                                <FormattedMessage
                                    id="no_results_for"
                                    defaultMessage="No results for {word}"
                                    values={{ word: item.label }}
                                />
                            </Typography>
                        }
                    </Box>
                </Grid>

            }
        </Grid>
    )
}

DetailsBlockContent.propTypes = {
    loading: PropTypes.bool,
    item: PropTypes.any,
    actionOpenNetwork: PropTypes.func,
}


export default DetailsBlockContent