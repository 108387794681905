import {
  Backdrop, Box, Chip, CircularProgress,
  Divider, Icon, IconButton,
  InputBase,
  Paper,
  Stack, Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import useMediaQuery from '@mui/material/useMediaQuery';
import NetworkGraph from '../components/NetworkGraph';
import React, { useState } from 'react';
import Swapeable from '../components/Swapeable';
import DetailsBlock from '../components/DetailsBlock';
import ListGraph from '../components/ListGraph';
import SearchResultsDialog from '../components/SearchResultsDialog';
import ConfigContainer from '../components/ConfigContainer';
import { FormattedMessage } from 'react-intl';

const defaultEN = { key: "en", language: "ENGLISH", text: "", color: "#00ACC1", colorName: "Blue" }
const defaultJP = { key: "jp", language: "JAPANESE", text: "", color: "#3F51B5", colorName: "Purple" }


const Tool = ({ setIsOpen, isOpen }) => {

  const [settings, setSettings] = useState({
    selectedView: "graph", weight: 2, maxWeight: 0, minWeight: 0,
    showGroupColors: false, showMiniTranslations: true,
    showFrequenciesValues: true
  });

  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("")
  const [loadingNodeContent, setLoadingNodeContent] = useState(false);

  const [currentNodeInfo, setCurrentNodeInfo] = useState({})
  const [searchHistory, setSearchHistory] = useState([])

  const [translateFrom, setTranslateFrom] = useState(defaultEN)
  const [translateTo, setTranslateTo] = useState(defaultJP)

  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [showDetailsSwapeableModal, setShowDetailsSwapeableModal] = useState(false)
  const [showConfigModal, setShowConfigModal] = useState(false)
  const [showShowSearchResultsModal, setShowSearchResultsModal] = useState(false)

  const [graphData, setGraphData] = useState({ nodes: [] });

  const [resultsData, setResultsData] = useState([])

  const userReducer = useSelector(state => state.userReducer);



  /* This function get the list of translations and show the first modal after the search function */
  async function searchWord(searchText) {

    try {
      setLoading(true);
      setTranslateTo({ ...translateTo, text: "" })
      const url = `/api/search/wordTrans/ver2?word=${searchText}`;
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const res = await response.json();

      let message = "No results. Please try again."
      if (userReducer.language == "jp") {
        message = "結果がありません。 もう一度やり直してください。"
      }
      if (response.status == 404) {
        throw message;
      }
      setShowDetailsModal(false);
      setCurrentNodeInfo({});
      setLoading(false);
      setResultsData(res.result || []);

      /* Save the word and translated work all across the application */
      if (res.fromLang == "en") {
        setTranslateFrom({ ...defaultEN, text: searchText });
        setTranslateTo(defaultJP);
      }

      if (res.fromLang == "jp") {
        setTranslateFrom({ ...defaultJP, text: searchText });
        setTranslateTo(defaultEN);
      }
      if (res.result.length > 1) {
        setShowSearchResultsModal(true)
      } else {
        await getGraph(res.fromLang, res.toLang, searchText, res.result[0].transResult);
      }
    } catch (error) {
      alert(error)
      setLoading(false);
    }
  }


  /* This function returns a list of nodes and links to draw the network graph and list view */
  async function getGraph(fromLang, toLang, word, selectedTranslation) {
    try {
      setLoading(true);
      setTranslateTo({ ...translateTo, text: "" })
      const url =
        `/api/search/nodes/?word1=${word}&lang1=${fromLang}&convertType=forward&lang2=${toLang}&word2=${selectedTranslation}`;
      console.log(url)
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });

      const res = await response.json();

      setTranslateTo({ ...translateTo, text: selectedTranslation });
      setGraphData(res);
      setShowSearchResultsModal(false);
      setSearchHistory([...searchHistory, {
        fromLanguage: fromLang, toLanguage: toLang,
        label: word, translatedWord: selectedTranslation
      }]);

      let maxWeight = 10;
      let minWeight = 0;

      setSettings({ ...settings, minWeight: minWeight, maxWeight: maxWeight });
      setLoading(false);
      return res.mainTranslation;
    } catch (error) {
      console.log(error);
      alert(error)
      setLoading(false);
    }
  }


  /* This function returns the details of the clicked node */
  async function getNodeDetails(fromLang, toLang, text) {
    try {
      setLoadingNodeContent(true);

      const url = `/api/search/word/?word=${text}&fromLang=${fromLang}&toLang=${toLang}`;
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });

      const res = await response.json();
      if (response.status == 404) {
        console.log(res.detail);
        setCurrentNodeInfo({});
        setShowDetailsModal(false)
        // eslint-disable-next-line
        throw "No result for " + text;
      }
      //console.log(res.text)
      setCurrentNodeInfo({
        ...res, label: text,
        mainTranslation: res.text,
        fromLanguage: fromLang,
        toLanguage: toLang
      });
      setLoadingNodeContent(false);
    } catch (error) {
      console.log(error);
      setLoadingNodeContent(false);
      setCurrentNodeInfo({});
      alert(error)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSearchEvent();

    }
  }

  const onSearchEvent = async () => {
    setSearchHistory([]);
    await searchWord(searchValue);
  }

  const onClickResult = (translatedWord) => async e => {
    e.preventDefault();
    await getGraph(translateFrom.key, translateTo.key, translateFrom.text, translatedWord);
  }

  const onClickNode = async (nodeInfo) => {
    setShowDetailsModal(true)
    setCurrentNodeInfo({})
    await getNodeDetails(nodeInfo.fromLanguage, nodeInfo.toLanguage, nodeInfo.label);
  }

  const goHistoryBack = (node, index) => async e => {
    e.preventDefault();
    await getGraph(node.fromLanguage, node.toLanguage, node.label, node.translatedWord)
    const newSearchHistory = [];
    for (let i = 0; i <= index; i++) {
      newSearchHistory.push(searchHistory[i])
    }
    setSearchHistory(newSearchHistory);
    if (node.fromLanguage === "en") {

      setTranslateFrom({ ...defaultEN, text: node.label });
      setTranslateTo({ ...defaultJP, text: node.translatedWord });
    } else {

      setTranslateFrom({ ...defaultJP, text: node.label });
      setTranslateTo({ ...defaultEN, text: node.translatedWord });
    }
  }

  const actionOpenNetwork = async (selectedWord) => {
    setShowDetailsModal(false)

    await getGraph(currentNodeInfo.fromLanguage, currentNodeInfo.toLanguage, currentNodeInfo.label, selectedWord)
    if (currentNodeInfo.fromLanguage === "en") {
      setTranslateFrom({ ...defaultEN, text: currentNodeInfo.label });
      setTranslateTo({ ...defaultJP, text: selectedWord });
    } else {
      setTranslateFrom({ ...defaultJP, text: currentNodeInfo.label });
      setTranslateTo({ ...defaultEN, text: selectedWord });
    }
    setCurrentNodeInfo({})
    setShowDetailsSwapeableModal(false)
  }

  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <Box sx={{ ml: 1 }}>
      <Paper
        elevation={6}
        sx={{
          p: 1,
          position: "fixed",
          left: isMobile ? 6 : (isOpen ? 250 : 6),
          top: 10,
          zIndex: 190, width: isMobile ? "92%" : 350
        }} >
        <Box sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
          <IconButton sx={{ p: '10px' }} aria-label="menu"
            onClick={() => setIsOpen(!isOpen)}>
            <Icon>menu</Icon>
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            value={searchValue}
            onKeyPress={handleKeyPress}
            onChange={e => setSearchValue(e.target.value.toLocaleLowerCase())}
          />
          <IconButton sx={{ p: '10px' }} aria-label="search"
            onClick={onSearchEvent}>
            <Icon>search</Icon>
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <ConfigContainer
            type="IconButton"
            settings={settings}
            setSettings={setSettings}
            showModal={showConfigModal}
            setShowModal={setShowConfigModal}
          />
        </Box>
      </Paper>
      {searchHistory.length > 1 &&
        <Paper className='paperShadow' sx={{
          p: 1,
          position: "fixed",
          top: 85,
          width: isMobile ? "92%" : 350,
          overflow: "auto",
          zIndex: 190,
        }}>
          <Stack spacing={1} direction={"row"}>
            {searchHistory.map((item, index) => (
              index === searchHistory.length - 1 ?
                <Box key={index} display={"flex"}>
                  <Chip sx={{ mr: 1 }} size='small' label={item.label + " / " + item.translatedWord} />
                </Box>
                :
                <Box key={index} display={"flex"}>
                  <Chip onClick={goHistoryBack(item, index)}
                    size='small' label={item.label + " / " + item.translatedWord} />
                  <Typography pl={1}>{">"}</Typography>
                </Box>
            ))}
          </Stack>

        </Paper>
      }
      <Box sx={{ overflow: "auto", height: "calc(100vh - 10px)", width: "100%" }}>
        {graphData.nodes.length > 0 ?
          <>
            {settings.selectedView == "graph" &&
              <NetworkGraph
                onClickNode={onClickNode}
                graphData={graphData}
                isMobile={isMobile}
                /* height={isMobile ? "1000" : "1000"}
                width={isMobile ? "600" : "1500"} */
                settings={settings}
                fromColor={translateFrom.color}
                toColor={translateTo.color}
                word={translateFrom.text}
                translatedWord={translateTo.text}
              />
            }
            {settings.selectedView == "list" &&
              <Box sx={{ ml: isMobile ? 0 : 50 }}>
                <ListGraph
                  settings={settings}
                  onClickNode={onClickNode}
                  fromColor={translateFrom.color}
                  toColor={translateTo.color}
                  graphData={graphData}
                  word={translateFrom.text}
                  translatedWord={translateTo.text}
                />
              </Box>
            }
          </>
          :
          <Box mt={isMobile ? 10 : 11} p={isMobile ? 2 : 0}>
            <FormattedMessage
              id="type_a_word_to_start"
              defaultMessage="Type a word in the box above and press search to start."
            />
          </Box>
        }
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SearchResultsDialog
        word={translateFrom.text}
        onClickResult={onClickResult}
        resultsData={resultsData}
        loading={loading}
        showModal={showShowSearchResultsModal}
        setShowModal={setShowSearchResultsModal}
      />

      {
        isMobile ?
          <Swapeable
            loading={loadingNodeContent}
            item={currentNodeInfo}
            showModal={showDetailsSwapeableModal}
            setShowModal={setShowDetailsSwapeableModal}
            actionOpenNetwork={actionOpenNetwork}
            onClickResult={onClickResult}
          />
          :
          <DetailsBlock
            loading={loadingNodeContent}
            item={currentNodeInfo}
            showModal={showDetailsModal}
            setShowModal={setShowDetailsModal}
            actionOpenNetwork={actionOpenNetwork}
            onClickResult={onClickResult}
          />
      }
    </Box >
  );
}

Tool.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
}


export default Tool;