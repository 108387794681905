import { Grid, Link, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types';

import TopBar from '../components/TopBar'


const Contact = ({ isOpen, setIsOpen }) => {
  return (
    <>
      <TopBar isOpen={isOpen} setIsOpen={setIsOpen} />
      <Container maxWidth="md" sx={{
        pt: 2
      }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h2">
              <FormattedMessage
                id="menu_contact"
                defaultMessage="Contact"
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5'>
              Maria Telegina
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>
              Email
            </Typography>
            <Typography variant='body1'>
              <Link href="mailto:maria.telegina@mail.u-tokyo.ac.jp">maria.telegina@mail.u-tokyo.ac.jp</Link>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>
              Address
            </Typography>
            <Typography variant='body1'>
              Tokyo College
              The University of Tokyo
              7-3-1 Hongo, Bunkyo-ku, Tokyo 113-0033
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5' sx={{ pt: 2 }}>
              Simon De Deyne
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>
              Email
            </Typography>
            <Typography variant='body1'>
              <Link href="mailto:simon.dedeyne@unimelb.edu.au">simon.dedeyne@unimelb.edu.au</Link>

            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>
              Address
            </Typography>
            <Typography variant='body1'>
              Complex Human Data Hub, Melbourne Connect Level 8, 700 Swanston Street, Carlton VIC, 3053, Australia
            </Typography>
          </Grid>
        </Grid>

      </Container>
    </>
  )
}

Contact.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
}


export default Contact