import {
    Box, Button, Chip, Grid, Icon, IconButton,
    ListItem, ListItemIcon, ListItemText, Slider, Stack, Switch,
    Typography
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react'
import { FormattedMessage } from 'react-intl';
import saveSvgAsPng from 'save-svg-as-png'
import PropTypes from 'prop-types';

const imageOptions = {
    scale: 5,
    encoderOptions: 1,
    backgroundColor: 'white',
}

const ConfigContent = ({ settings, setSettings, onClickClose }) => {


    const exportFile = () => {
        saveSvgAsPng.saveSvgAsPng(document.getElementById('svg-chart'), 'smallworld_network.png', imageOptions);
    }

    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <Grid sx={{ px: 2, py: 1, width: isMobile ? "auto" : "400px" }} container spacing={1}>
            <Grid item xs={12}>
                <Box display="flex" justifyContent={"center"} alignItems="center" pb={isMobile && 2}>
                    <Box flexGrow={1} display="flex" alignItems="center">
                        <Typography variant={isMobile ? "h5" : "h6"}>
                            <FormattedMessage
                                id="settings"
                                defaultMessage="Settings"
                            />
                        </Typography>
                    </Box>
                    <Box>
                        {/* <Button
                            onClick={exportFile}
                            startIcon={<Icon>save</Icon>}>
                            export graph
                        </Button> */}
                        <IconButton
                            onClick={onClickClose}
                            startIcon={<Icon>close</Icon>}
                        ><Icon>close</Icon></IconButton>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                {settings.selectedView == "graph" &&
                    <Button
                        sx={{ mt: 2 }}
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        onClick={exportFile}
                        startIcon={<Icon>save</Icon>}>
                        <FormattedMessage
                            id="export_graph"
                            defaultMessage="Export graph"
                        />
                    </Button>
                }
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2" sx={{ pb: 1 }}>
                    <FormattedMessage
                        id="visualisation_type"
                        defaultMessage="Visualisation type"
                    />
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Chip
                    label={
                        <FormattedMessage
                            id="graph"
                            defaultMessage="Graph"
                        />
                    }
                    sx={{ width: "100%" }}
                    onClick={() => setSettings({ ...settings, selectedView: "graph" })}
                    icon={<Icon>bubble_chart</Icon>}
                    color={settings.selectedView === "graph" ? "secondary" : "default"}
                />
            </Grid>
            <Grid item xs={6}>
                <Chip
                    label={
                        <FormattedMessage
                            id="list"
                            defaultMessage="List"
                        />
                    }
                    sx={{ width: "100%" }}
                    icon={<Icon>list</Icon>}
                    onClick={() => setSettings({ ...settings, selectedView: "list" })}
                    color={settings.selectedView === "list" ? "secondary" : "default"}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2" sx={{ pt: 2 }}>
                    <FormattedMessage
                        id="relationships"
                        defaultMessage="Relationships"
                    />
                </Typography>
                <Typography variant="body2" sx={{ pb: 2 }}>
                    <FormattedMessage
                        id="relationships_description"
                        defaultMessage="Filter the strongest relationships"
                    />
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Stack sx={{ px: 2 }} spacing={2} direction={"row"}>
                    <Slider
                        value={settings.weight}

                        valueLabelDisplay="auto"
                        min={settings.minWeight}
                        max={settings.maxWeight}
                        onChange={e => setSettings({ ...settings, weight: e.target.value })}
                        marks={[
                            {
                                value: settings.minWeight,
                                label: settings.minWeight,
                            },
                            {
                                value: settings.maxWeight,
                                label: settings.maxWeight,
                            }
                        ]}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <ListItem>
                    <ListItemIcon>
                        <Icon>palette</Icon>
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <FormattedMessage
                                id="show_groups"
                                defaultMessage="Show community groups"
                            />
                        }
                        secondary={
                            <FormattedMessage
                                id="show_groups_description"
                                defaultMessage="Strongly connected and might have similar meanings"
                            />
                        } />
                    <Switch
                        edge="end"
                        onClick={() => setSettings({ ...settings, showGroupColors: !settings.showGroupColors })}
                        checked={settings.showGroupColors}
                        inputProps={{
                            'aria-labelledby': 'switch-list-label-wifi',
                        }}
                    />

                </ListItem>
                {settings.selectedView == "list" &&
                    <ListItem>
                        <ListItemIcon>
                            <Icon>device_thermostat</Icon>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <FormattedMessage
                                    id="show_frequencies"
                                    defaultMessage="Show frequencies"
                                />
                            }
                            secondary={
                                <FormattedMessage
                                    id="show_frequencies_description"
                                    defaultMessage="How many people give a certain association to the keywords"
                                />
                            } />
                        <Switch
                            edge="end"
                            onClick={() => setSettings({ ...settings, showFrequenciesValues: !settings.showFrequenciesValues })}
                            checked={settings.showFrequenciesValues}
                        />
                    </ListItem>
                }
                {settings.selectedView == "graph" &&
                    <ListItem>
                        <ListItemIcon>
                            <Icon>bubble_chart</Icon>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <FormattedMessage
                                    id="show_radius"
                                    defaultMessage="Show dynamic radius"
                                />
                            }
                            secondary={
                                <FormattedMessage
                                    id="show_radius_description"
                                    defaultMessage="Represents response frequency"
                                />
                            }
                        />
                        <Switch
                            edge="end"
                            onClick={() => setSettings({ ...settings, showDynamicRadius: !settings.showDynamicRadius })}
                            checked={settings.showDynamicRadius}
                        />
                    </ListItem>
                }
                <ListItem>
                    <ListItemIcon>
                        <Icon>translate</Icon>
                    </ListItemIcon>
                    <ListItemText primary={
                        <FormattedMessage
                            id="show_minitranslations"
                            defaultMessage="Show Minitranslations"
                        />
                    } />
                    <Switch
                        edge="end"
                        onClick={() => setSettings({ ...settings, showMiniTranslations: !settings.showMiniTranslations })}
                        checked={settings.showMiniTranslations}
                    />
                </ListItem>
            </Grid>
            {/* <Grid item xs={12}>
                <Button
                    fullWidth
                    onClick={exportFile}
                    startIcon={<Icon>save</Icon>}>
                    export graph
                </Button>
            </Grid> */}
        </Grid>
    )
}

ConfigContent.propTypes = {
    settings: PropTypes.object,
    setSettings: PropTypes.func,
    onClickClose: PropTypes.func
}

export default ConfigContent