import {
    Box, Icon, IconButton, Paper, Skeleton,
    Typography
} from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types';
import DetailsBlockContent from './DetailsBlockContent'

const DetailsBlock = ({ item, showModal, setShowModal, actionOpenNetwork, loading }) => {


    return (
        showModal &&
        <Paper className='paperShadow' sx={{
            p: 2,
            position: "fixed",
            top: 135,
            width: 335
        }}>
            {item &&
                <>

                    <Box pb={2}>
                        <Box display="flex">
                            <Box flexGrow={1} display="flex" alignItems="center">
                                {loading ?
                                    <Skeleton animation="wave" height={15} width="50%" />
                                    :
                                    <>
                                        <Typography variant="h5"> {item.label}</Typography>
                                        {item.mainTranslation &&
                                            <Icon>arrow_forward</Icon>
                                        }
                                        <Typography variant="h5">{item.mainTranslation}
                                        </Typography>
                                    </>
                                }
                            </Box>
                            <IconButton onClick={() => setShowModal(false)}><Icon>close</Icon></IconButton>
                        </Box>
                        {item.pronunciation &&
                            <Typography variant="body1" fontStyle="italic">{item.pronunciation}</Typography>
                        }
                    </Box>
                    <Box sx={{
                        height: "calc(100vh - 320px)",
                        overflow: "auto"
                    }} >
                        <DetailsBlockContent loading={loading} item={item} actionOpenNetwork={actionOpenNetwork} />
                    </Box>
                </>
            }
        </Paper>
    )
}

DetailsBlock.propTypes = {
    item: PropTypes.object,
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    actionOpenNetwork: PropTypes.func,
    loading: PropTypes.bool
}


export default DetailsBlock