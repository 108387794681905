import styled from '@emotion/styled';
import {
    Box, Icon, IconButton,
    Skeleton, SwipeableDrawer, Typography
} from '@mui/material'
import { grey } from '@mui/material/colors';
import React from 'react'
import DetailsBlockContent from './DetailsBlockContent';
import PropTypes from 'prop-types';

const drawerBleeding = 56;

/* const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: grey[300],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
})); */

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));



const Swapeable = ({ item, showModal, setShowModal, actionOpenNetwork, loading }) => {


    return (
        item && item.label &&
        <>
            <SwipeableDrawer
                sx={{
                    '.MuiPaper-root': {
                        height: `calc(70% - ${drawerBleeding}px)`,
                        overflow: 'visible',
                        pointerEvents: "auto !important"
                    },
                }}
                /* container={container} */
                anchor="bottom"
                open={showModal}
                onClose={() => setShowModal(false)}
                onOpen={() => setShowModal(true)}
                swipeAreaWidth={drawerBleeding}
                /* disableSwipeToOpen={false} */
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <StyledBox
                    sx={{
                        position: 'absolute',
                        top: -drawerBleeding,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        visibility: 'visible',
                        right: 0,
                        left: 0,
                    }}
                >
                    {/* <Puller /> */}
                    <Box p={2}>
                        <Box display="flex">
                            <Box flexGrow={1} display="flex" alignItems="center">
                                {loading ?
                                    <Skeleton animation="wave" height={15} width="50%" />
                                    :
                                    <>
                                        <Typography variant="h6"> {item.label}</Typography>
                                        <Icon>arrow_forward</Icon>
                                        <Typography variant="h6">{item.mainTranslation}
                                        </Typography>
                                    </>
                                }
                            </Box>
                            <Box sx={{ zIndex: 1500 }}>
                                {showModal ?
                                    <IconButton

                                        size="small"
                                        onClick={() => setShowModal(false)}>
                                        <Icon fontSize='small'>close</Icon>
                                    </IconButton>
                                    : <IconButton

                                        size="small"
                                        onClick={() => setShowModal(true)}>
                                        <Icon fontSize='small'>expand_less</Icon>
                                    </IconButton>
                                }

                                {/* <Button size="small" variant='contained' onClick={openNewNode} startIcon={<Icon>bubble_chart</Icon>}>Open network</Button> */}
                            </Box>
                        </Box>
                    </Box>
                </StyledBox>
                <StyledBox
                    sx={{
                        px: 2,
                        pb: 4,
                        /* height: '100%', */
                        overflow: 'auto',
                    }}
                >

                    {item.pronunciation &&
                        <Box pt={1} pb={2}>
                            <Typography variant="body1" fontStyle="italic">{item.pronunciation}</Typography>
                        </Box>
                    }
                    {/* <Box display="flex" justifyContent="center" p={2}>
                        <Button variant='contained' onClick={openNewNode} startIcon={<Icon>bubble_chart</Icon>}>Open network</Button>
                    </Box> */}
                    <DetailsBlockContent loading={loading} item={item} actionOpenNetwork={actionOpenNetwork} />
                </StyledBox>
            </SwipeableDrawer>
        </>
    )
}

Swapeable.propTypes = {
    item:  PropTypes.any,
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    actionOpenNetwork: PropTypes.func,
    loading: PropTypes.bool,
}

export default Swapeable