import { Box, Button, Grid, Icon, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl';

import phone from '../assets/img/hand_phone.png'
import TopBar from '../components/TopBar'

const Home = ({ isOpen, setIsOpen }) => {

  let navigate = useNavigate();

  return (
    <>
      <TopBar isOpen={isOpen} setIsOpen={setIsOpen} />
      <Container maxWidth="md" sx={{
        p: 2
      }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h2">
              <FormattedMessage
                id="home_title"
                defaultMessage="SWOW- JP APP"
              />
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Box maxWidth={400} >
              <img src={phone} width="100%" alt="logo" />
            </Box>

          </Grid>
          <Grid item xs={12}>

            <Typography textAlign="justify">
              <FormattedMessage
                id="home_description"
                values={{
                  br: <br />,
                }}
                defaultMessage="What do words mean in different languages? When translating words in English or Japanese, their meaning is almost never identical. Not only are the languages different, speakers in Japan and Western countries where English is commonly spoken are exposed to a different natural and cultural environment. But what parts of meaning and what connotations are shared and what is language-specific? This app aims to provide intuitive usage and clear and concise data visualizations to learn about what meaning is shared and unique in English and Japanese. It uses word association data from the Small Word of Words project in Japanese (SWOW-JP) and English (SWOW-EN). It highlights what associations are common across speakers of these languages  and which are language-specific by aligning word association networks in these languages.{br}
                  This app is currently under development and new words are added from the results of international project Small World of Words, aiming to build a mental dictionary or lexicon in the major languages of the world and make this information widely available. Please visit the project’s web page to learn more about our research.                 
                "
              />
            </Typography>
            <Box display="flex" justifyContent="center" p={2}>
              <Button variant='contained' onClick={() => navigate("/tool")} startIcon={<Icon>bubble_chart</Icon>}>
                <FormattedMessage
                  id="button_go_to_the_tool"
                  defaultMessage="Go to the tool"
                />
              </Button>
            </Box>
          </Grid>
        </Grid>

      </Container>
    </>
  )
}

Home.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
}

export default Home