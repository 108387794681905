import {
    Dialog, Icon, IconButton, Menu,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react'
import ConfigContent from './ConfigContent.js';
import PropTypes from 'prop-types';


const ConfigContainer = ({ settings, setSettings }) => {

    /* Variables for Desktop version */
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    /* Variables for Desktop version */
    const [showModal, setShowModal] = useState(false);

    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <>
            {isMobile ?
                <>
                    <IconButton
                        onClick={() => setShowModal(true)}
                    >
                        <Icon>tune</Icon>
                    </IconButton>
                    <Dialog fullScreen open={showModal} onClose={() => setShowModal(false)}>
                        <ConfigContent settings={settings} setSettings={setSettings} onClickClose={() => setShowModal(false)} />
                    </Dialog>
                </>
                :
                <>
                    <IconButton
                        onClick={handleClick}
                    >
                        <Icon>tune</Icon>
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <ConfigContent settings={settings} setSettings={setSettings} onClickClose={handleClose} />
                    </Menu>
                </>
            }
        </>
    )
}
ConfigContainer.propTypes = {
    settings: PropTypes.object,
    setSettings: PropTypes.func
}

export default ConfigContainer