import {
    Drawer, Icon, List, ListItem, ListItemButton,
    ListItemIcon, ListItemText, MenuItem, TextField, Typography
} from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import userActions from "../redux/user/user.actions";
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl'
import logo from '../assets/img/SWOW_Title.png'
const menu = [
    {
        title: <FormattedMessage
            id="menu_home"
            defaultMessage="Home"
        />,
        icon: "home",
        route: "/"
    },
    {
        title: <FormattedMessage
            id="menu_tool"
            defaultMessage="Tool"
        />,
        icon: "bubble_chart",
        route: "/tool"
    },
    {
        title: <FormattedMessage
            id="menu_team"
            defaultMessage="Team"
        />,
        icon: "group",
        route: "/team"
    },
    {
        title: <FormattedMessage
            id="menu_contact"
            defaultMessage="Contact"
        />,
        icon: "email",
        route: "/contact"
    }
]

const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-input': {
        color: 'white',
    },
    '& label': {
        color: 'white',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: 'grey',
        },/*
        '&.Mui-focused fieldset': {
          borderColor: 'green',
        }, */
    },
    '& .MuiSelect-root': {
        color: "white"
    },
    '& .MuiSvgIcon-root': {
        color: "white"
    },
});


const SideBar = ({ drawerWidth, isOpen, setIsOpen, isMobile }) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const userReducer = useSelector(state => state.userReducer);

    const openRoute = route => (e) => {
        e.preventDefault()
        navigate(route)
        if (isMobile) {
            setIsOpen(false)
        }
    }

    const handleChange = e => {
        const { value } = e.target;
        /* console.log(value); */
        dispatch(userActions.setLanguage(value));
    }

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    backgroundColor: "#323233",
                    color: "white"
                },
            }}
            anchor='left'
            open={isOpen}
            variant={isMobile ? "temporary" : "persistent"}
            onClose={() => setIsOpen(false)}
        >
            <Box p={2} display="flex" justifyContent="center">
                <Typography variant='h6'>
                    <img src={logo} width="100%" alt="sidebar logo" />
                </Typography>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent={"space-between"} height="100%">
                <List>
                    {menu.map((item, key) => (
                        <ListItem key={key} disablePadding>
                            <ListItemButton onClick={openRoute(item.route)}>
                                <ListItemIcon sx={{ color: "white" }}>
                                    <Icon>{item.icon}</Icon>
                                </ListItemIcon>
                                <ListItemText primary={item.title} />
                            </ListItemButton>
                        </ListItem>
                    ))}

                </List>
                <Box p={1} pb={3}>
                    <CssTextField
                        fullWidth
                        select
                        label={<FormattedMessage
                            id="language_selector"
                            defaultMessage="Language selector"
                        />}
                        name="language"
                        value={userReducer.language}
                        onChange={handleChange}
                        variant="outlined"
                    >
                        <MenuItem value={"jp"}>日本語</MenuItem>
                        <MenuItem value={"en"}>English</MenuItem>
                    </CssTextField>
                </Box>
            </Box>
        </Drawer>
    )
}

SideBar.propTypes = {
    drawerWidth: PropTypes.number,
    isOpen: PropTypes.bool,
    isMobile:PropTypes.bool,
    setIsOpen: PropTypes.func,
}


export default SideBar