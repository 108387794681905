
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { bfsFilterNode } from '../utils/BFS.js';
import { languageColors, communityColors } from '../utils/colors.js';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';


const NetworkGraph = ({
  isMobile, word, translatedWord, graphData, width = 500,
  height = 500, onClickNode, settings
}) => {

  const svgRef = useRef();
  const userReducer = useSelector(state => state.userReducer);

  useEffect(() => {
    renderGraph()
    // eslint-disable-next-line
  }, [graphData, settings, isMobile, userReducer.language])


  const renderGraph = () => {

    const resultData = bfsFilterNode(settings.weight, graphData)
    const nodes = resultData.nodes || [];
    const links = resultData.links || [];

    const svg = d3.select(svgRef.current)
    svg.selectAll("*").remove();

    if (isMobile) {
      width = window.innerWidth - 10;
      height = window.innerHeight;
    } else {
      width = window.innerWidth - 10; // 280
      height = window.innerHeight - 30;
    }
    const centerX = width / 2;
    const centerY = height / 2;

    /* Settings connections */
    var linkedByIndex = {};
    for (let index = 0; index < links.length; index++) {
      const element = links[index];
      //console.log(element.source.id)
      const source = element.source.id || element.source;
      const target = element.target.id || element.target;
      linkedByIndex[source + "," + target] = 1;
      linkedByIndex[target + "," + source] = 1;
    }

    // Colouring
    var colorsScale = d3.scaleOrdinal(communityColors);

    /* Variants */
    var minRadius = d3.min(nodes, function (d) {
      return +d.r;
    });
    var maxRadius = d3.max(nodes, function (d) {
      return +d.r;
    });
    var minStroke = d3.min(links, function (d) {
      return +d.weight;
    });
    var maxStroke = d3.max(links, function (d) {
      return +d.weight;
    });
    var radiusScale = d3.scalePow().exponent(0.5).domain([minRadius, maxRadius]).range([18, 46]);
    var strokeScale = d3.scalePow().exponent(0.5).domain([minStroke, maxStroke]).range([0.1, 0.5]);
    svg
      .attr('width', width)
      .attr('height', height)

    const simulation = d3.forceSimulation(nodes)
      .force("link", d3.forceLink(links).id(d => d.id)/* .distance(0).strength(1) */)
      .force("charge", d3.forceManyBody().strength(-600).distanceMax(600).distanceMin(5))
      .force("collide", d3.forceCollide().radius(d => {
        if (translatedWord == d.label || word == d.label) return 60
        if (settings.showDynamicRadius) {
          return radiusScale(d.r) + 4;
        } else {
          return 35;
        }
      }).iterations(2))
      .force("center", d3.forceCenter(centerX, centerY));

    if (isMobile) {
      simulation
        .force("x", d3.forceX().strength(0.1).x(200))
    } else {
      simulation
        .force("y", d3.forceY().strength(0.1).y(50))
    }

    const lines = svg.selectAll('line')
      .data(links)
      .enter()
      .append('line')
      .attr("class", "svggroup")
      .attr('stroke', () => {
        //console.log(d.source)
        /* if (d.source == leftRootNode || d.source.id == leftRootNode) return 'black'
        if (d.target == leftRootNode || d.target.id == leftRootNode) return 'black'
        if (d.type === "simulated") return "green" */
        return 'black'
      })
      .style("stroke-dasharray", d => {
        if (d.type === "simulated") return "5,5"
        return ""
      })
      .style("stroke-opacity", function (d) {
        return strokeScale(d.weight);
      });

    const node = svg.selectAll(".node")
      .data(nodes)
      .enter()
      .append("g")
      .attr("class", "node svggroup");


    const circles = node
      .append('circle')
      .style("opacity", () => {
        return 0.9
      })
      .on("click", function (d) {
        //updateSearch(d.label);
        const opacity = 0.2;
        const width = 3;
        const a = d.target.__data__.id;
        /* node.attr("class", (o) => {
          const b = o.id;
          if ((linkedByIndex[a + "," + b]) || (linkedByIndex[b + "," + a]) || (a == b)) {
            return "transforScaleOn"
          }
          return "transforScaleOff"
        }); */
        node.style("opacity", (o) => {
          const b = o.id;
          if ((linkedByIndex[a + "," + b]) || (linkedByIndex[b + "," + a]) || (a == b)) {
            return 1
          }
          return opacity
        });


        lines.style("stroke-width", function (o) {
          const source = o.source.id || o.source;
          const target = o.target.id || o.target;
          return source === a || target === a ? width : 0.5;
        });
        onClickNode(d.target.__data__)
      })
      .style("fill", function (d) {
        if (word == d.label) return "white"
        if (translatedWord == d.label) return "white"
        /* if (settings.showFrequenciesColors) {
          if (d.fromLanguage == "en") {
            return frequencyScaleEN(d.weight);
          }
          if (d.fromLanguage == "jp") {
            return frequencyScaleJP(d.weight);
          }
        } */
        if (settings.showGroupColors) {
          return colorsScale(d.group);
        }
        return "white";
      })
      .attr('stroke', d => {
        if (word == d.label && (d.fromLanguage == "en")) return languageColors["en"];
        if (word == d.label && (d.fromLanguage == "jp")) return languageColors["jp"];

        if (translatedWord == d.label && (d.fromLanguage == "jp")) return languageColors["jp"];
        if (translatedWord == d.label && (d.fromLanguage == "en")) return languageColors["en"];

        if (d.repeated) return "green"

        if (d.fromLanguage == "en") return languageColors["en"];
        if (d.fromLanguage == "jp") return languageColors["jp"];


        return "black"
      })
      .style("stroke-width", d => {
        if (word == d.label) return 3
        if (translatedWord == d.label) return 3
        if (d.repeated) return 3
        return 1
      })
      .style("stroke-dasharray", () => {
        /* if (translatedWord == d.label || word == d.label) return ""
        if (d.repeated) return "5,5" */
        return ""
      })
      .attr("r", function (d) {
        if (translatedWord == d.label || word == d.label) return 50
        if (settings.showDynamicRadius) {
          return radiusScale(d.r)
        }
        return 30/* 30 */;
      });

    const texts = node
      .append('text')
      .attr("dy", () => {
        if (settings.showMiniTranslations) return "-.3em"
        return ""
      })
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr("font-size", 13)
      .style('pointer-events', 'none')
      .style('user-select', 'none')
      .text(d => d.label)

    let subTexts;
    if (settings.showMiniTranslations) {
      subTexts = node
        .append('text')
        .attr("dy", "1.0em")
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .style('pointer-events', 'none')
        .attr("font-family", "Gill Sans", "Gill Sans MT")
        .attr("font-size", 12)
        .style('user-select', 'none')
        .text(d => {
          if (word == d.label || (translatedWord == d.label)) {
            let graphNodesTitle = { en: "English Network", jp: "Japanese Network" }
            if (userReducer.language == "jp") {
              graphNodesTitle = { en: "英語ネットワーク", jp: "日本のネットワーク" }
            }
            if (d.fromLanguage == "en") {
              return graphNodesTitle.en
            }
            if (d.fromLanguage == "jp") {
              return graphNodesTitle.jp
            }
          }
          return d.mainTranslation
        })
    }

    svg
      .on("click", (d) => {
        if (d.target.tagName !== "circle" && d.target.tagName !== "line") {
          console.log(d.target.tagName)
          const opacity = 0.9;
          const width = 0.5;
          //svg.selectAll(".node")
          node.style("opacity", opacity)
          lines.style("stroke-width", width);
          /* node.attr("class", "transforScaleOff"); */
        }
      })

    var zoom = d3.zoom().on('zoom', ({ transform }) => {
      svg.selectAll(".svggroup").attr("transform", transform);
      //g.attr("transform", transform);
    });
    svg.call(zoom)

    simulation.on('tick', () => {
      node
        .attr('cx', d => d.x)
        .attr('cy', d => d.y);
      circles
        .attr('cx', d => d.x)
        .attr('cy', d => d.y);

      texts
        .attr('x', d => d.x)
        .attr('y', d => d.y);

      if (settings.showMiniTranslations) {
        subTexts
          .attr('x', d => d.x)
          .attr('y', d => d.y);
      }

      lines
        .attr('x1', d => {
          return d.source.x
        })
        .attr('y1', d => d.source.y)
        .attr('x2', d => d.target.x)
        .attr('y2', d => d.target.y)

    })

  }

  return (
    <>
      <svg
        id={"svg-chart"}
        ref={svgRef}
        style={{
          marginRight: "0px",
          marginLeft: "0px",
        }}
      >
      </svg>
    </>
  );
}

NetworkGraph.propTypes = {
  isMobile:  PropTypes.bool,
  word:  PropTypes.string,
  translatedWord:  PropTypes.string,
  graphData: PropTypes.any,
  width: PropTypes.number,
  height: PropTypes.number,
  onClickNode: PropTypes.func,
  settings: PropTypes.object,
}

export default NetworkGraph;